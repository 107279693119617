<script>
import APIAdmin from '@app/services/API/Admin';

export default {
  data() {
    return {
      oldEmail: '',
      newEmail: '',
      ownerEmail: '',
      isLoading: false,
    };
  },
  methods: {
    updateEmail() {
      this.isLoading = true;
      const data = { old_email: this.oldEmail, new_email: this.newEmail };
      APIAdmin.updateEmail(data)
        .then(() => {
          this.$buefy.dialog.alert(`
            Modification effectuée !
          `);
          this.oldEmail = '';
          this.newEmail = '';
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    disconnectStripe() {
      this.isLoading = true;
      const data = { owner_email: this.ownerEmail };
      APIAdmin.disconnectStripe(data)
        .then(() => {
          this.$buefy.dialog.alert(`
            Le compte Stripe a bien été déconnecté !
          `);
          this.ownerEmail = '';
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<template>
  <section>
    <header class="level">
      <div class="level-left is-block w-60p">
        <h1 class="title is-size-4-mobile mb-2">
          Opérations d'administration
        </h1>
        <h3 class="title is-5 mt-5">
          Changement d'adresse email
        </h3>
        <form @submit.prevent="updateEmail">
          <b-field label="Ancien email">
            <b-input v-model="oldEmail" placeholder="ancien email" type="email" required />
          </b-field>
          <b-field label="Nouvel email">
            <b-input v-model="newEmail" placeholder="nouvel email" type="email" required />
          </b-field>
          <div class="buttons is-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Mettre à jour
            </b-button>
          </div>
        </form>
        <hr/>
        <h3 class="title is-5 mt-5">
          Déconnection Stripe
        </h3>
        <form @submit.prevent="disconnectStripe">
          <b-field label="Adresse email du propriétaire">
            <b-input v-model="ownerEmail"
                     placeholder="adresse email du propriétaire du compte"
                     type="email"
                     required />
          </b-field>
          <div class="buttons is-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Mettre à jour
            </b-button>
          </div>
        </form>
      </div>
    </header>
  </section>
</template>
